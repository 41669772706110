import React from 'react';
import DeleteItemButton from './DeleteItemButton';
import QtyChangeOption from '../checkout/QtyChangeOption';
import Link from 'next/link';
const CartItem = ({ item, onClose, id }) => {
  const { list_price, order_qty, product_id, qty_available, product_name, item_image, item_slug, product_description, cart_id } = item;
  const redirectLink = `/product/${item_slug}/buy/${product_id}`;
  return (
    <div>
      <li className='flex text-start text-black'>
        <div className='h-20 w-20 flex-shrink-0 overflow-hidden rounded-md '>
          <Link onClick={() => onClose()} href={redirectLink}>
            <img
              src={item_image}
              alt={product_name}
              className='h-full w-full object-contain object-center'
            />
          </Link>
        </div>

        <div className='ml-4 flex flex-1 flex-col'>
          <div>
            <div className='flex justify-between text-base font-medium text-gray-900'>
              <h3>
                <Link
                  onClick={() => onClose()}
                  href={redirectLink}
                  className='hover:text-blue-500'
                >
                  {product_name}
                </Link>
              </h3>
              <p className='hidden md:block ml-4 whitespace-nowrap'>₹ {list_price}</p>
            </div>
            <p className='hidden md:block mt-1 text-sm text-gray-500'>
              {product_description?.slice(0, 20)}
            </p>

          </div>
          <div className='flex flex-1 items-center justify-between pt-1 text-sm'>
            <div className='flex items-center'>
              <p className='block md:hidden mr-2 whitespace-nowrap'>₹ {list_price}</p>
              <QtyChangeOption
                qty_available={qty_available}
                order_qty={order_qty}
                id={cart_id}
                style={'w-5 h-5'}
              />

            </div>
            <DeleteItemButton id={cart_id} />
          </div>
        </div>
      </li>

    </div>
  );
};

export default CartItem;
