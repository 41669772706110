'use client';
import React, { useState, useEffect, useMemo } from 'react';
import { useWishList } from '@/context/wishlist/WishListContext';
import './button.css';
import { faL } from '@fortawesome/free-solid-svg-icons';

const AddToWishList = ({
  productId,
  productName,
  productPrice,
  productCategory,
  style,
}) => {
  const { wishList, addWishList, deleteWishList } = useWishList();
  const [isSpinning, setIsSpinning] = useState(false);
  const [isMouseIn, setIsMouseHover] = useState(false);

  const inWishlist = useMemo(() => {
    return wishList?.some((item) => item.id === productId);
  }, [wishList, productId]);

  useEffect(() => {
    if (isSpinning) {
      setTimeout(() => {
        setIsSpinning(false);
      }, 1000);
    }
  }, [isSpinning]);

  const handleClick = (id, type) => {
    setIsSpinning(true);
    if (wishList?.some((item) => item.id === id && item.type === type)) {
      deleteWishList(id);
    } else {
      addWishList(id, type).then((message) => {
        console.log(message);
        // Trigger the gtag event
        gtag('event', 'add_to_wishlist', {
          currency: 'INR',
          value: productPrice,
          items: [
            {
              item_id: id,
              item_name: productName,
              item_category: productCategory,
              price: productPrice,
              quantity: 1,
            },
          ],
        });
      });
    }
  };

  const isLiked = useMemo(() => {
    return wishList?.some(
      (item) => item.id === productId && item.type === 'like'
    );
  }, [wishList, productId]);

  const isHearted = useMemo(() => {
    return wishList?.some(
      (item) => item.id === productId && item.type === 'heart'
    );
  }, [wishList, productId]);

  const isSaved = useMemo(() => {
    return wishList?.some(
      (item) => item.id === productId && item.type === 'save'
    );
  }, [wishList, productId]);

  return (
    <div
      onMouseEnter={() => setIsMouseHover(true)}
      onMouseLeave={() => setIsMouseHover(false)}
      className={`${isMouseIn
        ? 'wishlist-container mr-0 bg-white pl-4 drop-shadow-sm transition duration-300 ease-in-out'
        : 'mr-2'
        } duration-50 group flex flex-row items-center space-x-1 p-1 transition ease-in-out hover:pr-2`}
    >
      <button
        onClick={() => {
          handleClick(productId, 'save');
        }}
        disabled={isSpinning}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill={`${isSaved ? 'black' : 'none'}`}
          viewBox='0 3 24 24'
          strokeWidth='1.2'
          stroke='currentColor'
          className={`h-6 w-6 ${isSaved ? 'text-black' : 'text-black'}`}
          style={{ zIndex: inWishlist ? 10 : 1 }}
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z'
          />
        </svg>
      </button>
      <div className='hidden flex-row items-center space-x-1 group-hover:flex group-hover:transition group-hover:duration-100 group-hover:ease-in-out'>
        <button
          onClick={() => {
            handleClick(productId, 'heart');
          }}
          disabled={isSpinning}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill={`${isHearted ? '#F91780' : 'none'}`}
            viewBox='0 0 24 24'
            strokeWidth={1.2}
            stroke='currentColor'
            className={`h-6 w-6 ${isHearted ? 'text-[#F91780]' : 'text-black'}`}
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z'
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default AddToWishList;
