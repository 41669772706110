'use client';
import React, { useState, useMemo } from "react";
import useCustomSWR from "@/hook/useCustomSWR";
import useDeviceType from "@/hook/useDeviceType";
const HomepageNavigation = () => {
    const deviceType = useDeviceType()
    const [activeTab, setActiveTab] = useState(null);
    const { data: menu, error } = useCustomSWR(
        `/v1/categories/get/menu?page=1&limit=500`,
        true
    );
    const subCategory = useMemo(() => {
        // Find the category where the category_name matches the activeTab
        if (!menu?.parentCategories) return
        const matchedCategory = menu?.parentCategories.find(
            (category) => category.category_name === activeTab
        );

        // Return subCategories if a match is found, otherwise return an empty array
        return matchedCategory ? matchedCategory.subCategories || [] : [];
    }, [activeTab, menu?.parentCategories]);

    if (deviceType !== 'laptop') return
    return (
        <div className="sticky top-14 z-20 py-1 px-6 border-gray-200  bg-white">
            <div className="   relative max-w-6xl mx-auto">
                {/* Navigation */}
                <div className="flex justify-between items-center  z-10 relative">
                    <a
                        onMouseEnter={() => setActiveTab(null)}
                        href="/category"
                        target="_blank"
                        className={`uppercase px-4 py-2 text-xs font-bold cursor-pointer  
                             hover:text-blue-500 hover:border-b-2 hover:border-blue-500
                             text-gray-500
                            }`}
                    >
                        Category
                    </a>
                    {menu?.parentCategories?.map((category, index) => (
                        <a
                            key={index}
                            href={category.category_slug}
                            target="_blank"
                            onMouseEnter={() => setActiveTab(category.category_name)}
                            className={`uppercase px-4 py-2 text-xs font-medium cursor-pointer ${activeTab === category.category_name
                                ? "text-blue-500 border-b-2 border-blue-500"
                                : "text-gray-500"
                                }`}
                        >
                            {category.category_name}
                        </a>
                    ))}
                    <a
                        href='/brand'
                        target="_blank"
                        onMouseEnter={() => setActiveTab("Brands")}
                        className={`uppercase px-4 py-2 text-xs font-bold cursor-pointer  ${activeTab === "Brands"
                            ? "text-blue-500 border-b-2 border-blue-500"
                            : "text-gray-500"
                            }`}
                    >
                        Brands
                    </a>
                    <a
                        onMouseEnter={() => setActiveTab("Awards")}
                        className={`uppercase px-4 py-2 text-xs font-bold cursor-pointer  ${activeTab === "Awards"
                            ? "text-blue-500 border-b-2 border-blue-500"
                            : "text-gray-500"
                            }`}
                    >
                        Awards
                    </a>
                    <a
                        onMouseEnter={() => setActiveTab('Country')}
                        className={`uppercase px-4 py-2 text-xs font-bold cursor-pointer  ${activeTab === "Country"
                            ? "text-blue-500 border-b-2 border-blue-500"
                            : "text-gray-500"
                            }`}
                    >
                        Country
                    </a>
                </div>

                {/* Content */}
                <div
                    className="absolute top-full z-10 left-0 w-full "
                    onMouseLeave={() => setActiveTab(null)} // Clear active tab on mouse leave
                >
                    {activeTab === "Brands" && (
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 p-4 bg-white border rounded-md shadow-sm">
                            {menu?.brandList?.map((brand, index) => (
                                <a
                                    href={`/brand/${brand.slug}`}
                                    target="_blank"
                                    key={index}
                                    className="text-xs text-center  flex justify-start space-x-2 items-center"
                                >
                                    <span>
                                        <img className="w-4" src={brand.brand_logo_url} alt={brand.brand_name} />
                                    </span>
                                    <span className="font-medium text-gray-700">
                                        {brand.brand_name}
                                    </span>
                                </a>
                            ))}
                        </div>
                    )}
                    {activeTab === "Awards" && (
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 p-4 bg-white border rounded-md shadow-sm">
                            {menu?.awards?.map((award, index) => (
                                <a
                                    href={`/product?name=award&id=${award._id}`}
                                    target="_blank"
                                    key={index}
                                    className="text-xs text-center  flex justify-start space-x-2 items-center"
                                >
                                    <span>
                                        <img className="w-4" src={award.image} alt={award.award_name} />
                                    </span>
                                    <span className="font-medium text-gray-700">
                                        {award.award_name}
                                    </span>
                                </a>
                            ))}
                        </div>
                    )}
                    {activeTab === "Country" && (
                        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 p-4 bg-white border rounded-md shadow-sm">
                            {menu?.country?.map((country, index) => (
                                <a
                                    href={`/product?name=country&id=${country._id}`}
                                    target="_blank"
                                    key={index}
                                    className="text-xs  text-center  flex justify-start space-x-2 items-center"
                                >
                                    <span>
                                        <img className="w-4" src={country.image} alt={country.country_name} />
                                    </span>
                                    <span className="font-medium text-gray-700">
                                        {country.country_name}
                                    </span>
                                </a>
                            ))}
                        </div>
                    )}
                    {
                        (activeTab == 'Office Product' || activeTab == '3C Products' || activeTab == 'Home & Kitchen' || activeTab == 'Baby & Kids' || activeTab == 'Lifestyle') ? (
                            <ul className='p-4 border rounded-md shadow-sm bg-white flex gap-6 px-8 capitalize'>
                                {subCategory?.map(
                                    (subCategory, index) => (
                                        <div
                                            key={index}
                                            className='mb-2 px-1 py-1 text-xs text-gray-700'
                                        >
                                            <a target='_blank'
                                                href={`${subCategory.subCategoriesPath}`}
                                                className='font-semibold'>
                                                {subCategory.category_name}
                                            </a>
                                            {subCategory.childCategories &&
                                                subCategory.childCategories.length > 0 && (
                                                    <ul className='mt-2 flex flex-col'>
                                                        {subCategory.childCategories.map(
                                                            (childCategory, childIndex) => (
                                                                <a
                                                                    key={childIndex}
                                                                    className='pb-2 text-gray-600 hover:font-semibold'
                                                                    target="_blank"
                                                                    href={`${childCategory.childCategoriesPath}`}
                                                                >
                                                                    {childCategory.category_name}
                                                                </a>
                                                            )
                                                        )}
                                                    </ul>
                                                )}
                                        </div>
                                    )
                                )}
                            </ul>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(HomepageNavigation);
