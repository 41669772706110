'use client';
import React, { useState, useMemo } from 'react';
import AddToCart from './AddToCard';
import AddToWishList from '../wishlist/AddToWishList';
import BuyNow from './BuyNow';
import Link from 'next/link';
import useDeviceType from '@/hook/useDeviceType';
const Card = (props) => {
  const {
    _id,
    product_images,
    item_name,
    item_slug,
    brand,
    country,
    inventory,
    awards,
    in_wishlist,
  } = props.product;
  const type = props.type;
  const images = useMemo(() => {
    if (!product_images) return [];
    return Object.values(product_images).filter((image) => image);
  }, [product_images]);
  const [img, setImg] = useState(
    images[Math.floor(Math.random() * images.length + 1)] ||
    product_images?.main_img
  );

  const handleMouseEnter = () => {
    setImg(
      images[Math.floor(Math.random() * images.length + 1)] ||
      product_images?.main_img
    );
  };
  const handleMouseLeave = () => {
    setImg(
      images[Math.floor(Math.random() * images.length + 1)] ||
      product_images?.main_img
    );
  };
  const deviceType = useDeviceType();
  const redirectLink = `/product/${item_slug}/buy/${_id}`;
  const brandPageLink = `/brand/${brand?.slug}`;
  let itemName = ''
  if (deviceType == 'mobile') {
    itemName = item_name.length > 20 ? `${item_name.slice(0, 20)}...` : item_name
  } else if (deviceType == 'tablet') {
    itemName = item_name.length > 25 ? `${item_name.slice(0, 25)}...` : item_name
  } else if (deviceType == 'laptop') {
    itemName = item_name.length > 32 ? `${item_name.slice(0, 32)}...` : item_name
  }
  return (
    <div
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      className='relative flex max-h-fit w-full flex-col justify-between rounded-md shadow hover:scale-[1.02] hover:duration-500'
    >
      <div className='absolute -right-1 top-0'>
        <AddToWishList
          productCategory={inventory?.parent_category}
          productPrice={inventory?.list_price}
          productName={item_name}
          productId={_id}
          style={' rounded-full bg-white'}
        />
      </div>
      <div className='h-40 w-full rounded-tl-md rounded-tr-md sm:h-52 md:h-80 relative'>
        <Link target='_blank' href={redirectLink}>
          <img
            loading='lazy'
            className='animate-fade-in h-full w-full rounded-tl-md rounded-tr-md object-cover'
            src={img}
            width={500}
            height={500}
            alt=''
          />
        </Link>
        {
          !inventory?.qty_available && (
            <div className='top-1/2 -translate-y-1/2 absolute inset-x-0 bg-[#21252982] bg-opacity-20 text-white flex justify-center items-center p-2'>
              <span className='text-base md:text-xl  font-medium text-[#FFFFFF] '>
                Out of Stock
              </span>
            </div>
          )
        }
      </div>
      <div className='flex flex-col justify-between p-2 sm:pb-4 sm:px-4 h-40'>
        <div >
          <div className='mt-1 h-7 w-7 overflow-hidden'>
            <Link target='_blank' href={brandPageLink}>
              <img
                loading='lazy'
                className='h-full w-full rounded-full object-contain drop-shadow'
                src={brand?.brand_logo_url}
                alt=''
              />
            </Link>
          </div>
          <div className='flex items-center gap-2'>
            <Link target='_blank' href={brandPageLink} className='text-sm md:text-base'>
              {brand?.brand_name}
            </Link>
            <img
              loading='lazy'
              className='h-5 w-5 rounded-full'
              src={country?.image}
              alt={country?.name}
            />
          </div>
          <Link
            target='_blank'
            href={redirectLink}
            className='font-sans text-[12px] text-gray-900 hover:text-blue-500 sm:text-sm leading-3'
          >
            {itemName}
          </Link>
          {
            inventory?.qty_available ? (
              <div className='flex justify-start gap-2'>
                <p href='#' className='text-[12px] font-medium sm:text-sm'>
                  ₹ {inventory?.list_price}
                </p>
                <p
                  href='#'
                  className='text-[12px] text-gray-400 line-through sm:text-sm'
                >
                  {inventory?.discount_percent ? (
                    <>{'₹' + inventory?.max_retail_price}</>
                  ) : null}
                </p>
                {inventory?.discount_percent != 0 && (
                  <p className='text-[12px] text-[#068743] sm:text-sm'>
                    {inventory?.discount_percent}%
                  </p>
                )}
              </div>
            ) : null
          }
        </div>
        <div className='flex flex-row items-center justify-between gap-1 space-x-2 pt-1'>
          <div className='flex gap-1 sm:gap-2'>
            {awards &&
              awards.map((award, index) => {
                if (index < 3) {
                  return (
                    <img
                      loading='lazy'
                      key={index}
                      className='h-3 w-3 rounded-full object-contain sm:h-5 sm:w-5'
                      src={award?.image}
                      alt={award?.award_name}
                    />
                  );
                }
              })}
          </div>
          {type === 'buyNow' ? (
            <BuyNow
              productId={_id}
              availableForSale={inventory?.qty_available}
              orderQty={1}
            />
          ) : (
            <AddToCart
              key={_id}
              productId={_id}
              orderQty={1}
              productName={item_name}
              productPrice={inventory?.list_price}
              productBrand={brand.brand_name}
              productCategory={country.country_name}
              availableForSale={inventory?.qty_available}
              className='rounded bg-black text-xs text-white '
              iconColor={`#3A4980`}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Card;
