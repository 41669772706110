'use client';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import BASE_URL from './constants';
import { HoverBorderGradient } from './ui/hover-border-gradient';
const Subscription = () => {
  const [email, setEmail] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${BASE_URL}/v1/subscription/subscribe`;
    const postData = {
      email,
    }; // Replace this with your actual data

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData), // Convert your data to JSON format
    };

    try {
      const res = await fetch(apiUrl, requestOptions);
      if (res.ok) {
        localStorage.setItem('subscribed', true);
        toast.success('Subcribed Successfully');
        setEmail('');
      }
      if (res.status == '400') {
        toast.error('Already Subscribed');
      }
      // Handle the response here
    } catch (error) {
      // Handle any errors
      console.error('Error:', error);
    }
  };
  return (
    <form className='w-80' onSubmit={(e) => handleSubmit(e)}>
      <div className='flex flex-col   items-start'>
        <h4 className='text-[#FFFFFF]'>News letter</h4>
        <div className='w-full flex justify-center items-center mt-2'>
          <input
            type='email'
            required
            value={email}
            placeholder='Enter Your Email Address'
            onChange={(e) => setEmail(e.target.value)}
            className='w-full border-b text-[#FFFFFF] border-[#FFFFFF] border-opacity-25 bg-transparent  outline-none'
          />
          <SubscribeLogo />
        </div>

      </div>
    </form>
  );
};

export default Subscription;

const SubscribeLogo = () => {
  return (
    <button type='submit' className='ms-2'>
      <span className='cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white opacity-50">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
        </svg>
      </span>
    </button>

  );
};
