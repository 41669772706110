'use client';
import React, { useState } from 'react';
const WishListButton = ({ count }) => {
  return (
    <>
      <div className='relative'>
        <button>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className={`z-0 h-6 w-6`}
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z'
            />
          </svg>
        </button>
        {count ? (
          <div className='absolute right-0 top-0 -mr-3 -mt-3 flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-red-500 text-xs text-white'>
            {count}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default WishListButton;
