'use client';
import React, { useMemo } from 'react';
import Link from 'next/link';

const RedirectLink = ({ name, id, section, listData }) => {
  const link = name && id ? `/product?name=${name}&id=${id}` : '/product';
  const exploreAllLink = name ? `/product?name=${name}` : '/product';
  const selectedName = useMemo(() => {
    return listData?.find((item) => item._id === id)?.name;
  }, [listData, id]);
  return (
    <div className='flex flex-col items-center justify-center py-2 mt-5'>
      {selectedName && name !== 'category' && (
        <Link
          href={link}
          className='flex w-fit items-center gap-2 rounded px-4 text-center text-base md:text-lg font-normal text-gray-700'
        >
          {` Explore ${selectedName} --> `}
        </Link>
      )}

      <Link
        href={exploreAllLink}
        className='flex w-fit items-center gap-2 rounded px-4 text-center text-base md:text-lg font-normal text-gray-700'
      >
        {`Explore all ${section} -->`}
      </Link>
    </div>
  );
};

export default RedirectLink;
