'use client';
import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useCart } from '@/context/cart/CartContext';
import { toast } from 'react-hot-toast';
const QtyChangeOption = ({ order_qty, qty_available, id, style }) => {
  const [qty, setQty] = useState();
  const { isMutating, changeQty, quickBuyQuantityChange } = useCart();
  const sessionId = localStorage.getItem('sessionId');
  console.log('sessionId', sessionId);
  const handleInput = (e, availableQty, id) => {
    const { value } = e.target;
    console.log('sessionId - inside handleinput', sessionId);
    if (value <= availableQty && value > 0) {
      if (sessionId) {
        console.log('quick buy qty change', sessionId, value);
        return quickBuyQuantityChange(sessionId, value);
      }
      console.log('change qty', id, value);
      changeQty(id, value)
        .then((data) => { })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error(`Enter Quantity min 1 and max ${availableQty}`);
    }
  };
  const debouncedHandleInput = debounce(handleInput, 500);

  const handleQtyChange = (type, productId) => {
    if (type == 'plus') {
      let value = Number(order_qty + 1);

      if (value <= qty_available) {
        if (sessionId) {
          return quickBuyQuantityChange(sessionId, value);
        }
        changeQty(productId, value)
          .then((data) => { })
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error(`Quantity not available.`);
      }
    }
    if (type == 'minus') {
      if (order_qty == 1) return null;
      let value = Number(order_qty - 1);
      if (sessionId) {
        return quickBuyQuantityChange(sessionId, value);
      }

      changeQty(productId, value)
        .then((data) => { })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    setQty(order_qty);
  }, [order_qty]);
  return (
    <div
      className={`${isMutating ? 'pointer-events-none opacity-75' : ''} flex space-x-2 items-center justify-center`}
    >
      <button
        onClick={() => handleQtyChange('minus', id)}
        className={`${style} shrink-0 flex items-center justify-center border rounded-full text-black`}
        disabled={isMutating}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          className='h-5 w-5'
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M18 12H6' />
        </svg>
      </button>

      <span className='font-medium'>{qty}</span>
      <button
        onClick={() => handleQtyChange('plus', id)}
        className={`${style} shrink-0 flex items-center justify-center border rounded-full text-black`}
        disabled={isMutating}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          className='h-5 w-5'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M12 6v12m6-6H6'
          />
        </svg>
      </button>
    </div>
  );
};

export default QtyChangeOption;
