'use client';
import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
const FloatingAppQr = () => {
    const [isClicked, setIsClicked] = useState(false);
    const sidebarRef = useRef(null);

    // Event Listener to collapse on outside click
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
                setIsClicked(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div ref={sidebarRef} className="fixed z-20 top-1/2 -translate-y-1/2 right-0">
            {/* Sidebar Container */}
            <div
                className={`bg-black text-white p-4 max-md:py-5 rounded-l-full md:rounded-l-lg flex items-center  justify-start md:justify-between  focus:outline-0 ${isClicked ? 'w-36 md:w-44 h-8 md:h-48' : 'w-6 md:w-10 h-7 md:h-40'
                    } transition-all duration-300`}
            >
                {/* Content to show when expanded */}
                <div
                    className={` focus:outline-0 hidden md:flex flex-col items-center justify-center space-y-2 transition-opacity duration-500 ${isClicked ? 'opacity-100' : 'opacity-0'
                        }`}
                >
                    {isClicked && (
                        <>
                            <img
                                src="https://res.cloudinary.com/genx21/image/upload/v1734506470/invoice/uthdphlptfsvow81n7px.png"
                                alt="QR Code"
                                className="w-32 h-32 rounded-lg"
                                loading="lazy"
                            />
                            <Link
                                href={'https://play.google.com/store/apps/details?id=com.genxproject'}
                                legacyBehavior
                                className='text-sm'
                            >
                                Android App
                            </Link>
                        </>
                    )}
                </div>
                <div
                    className={` focus:outline-0 block md:hidden flex-col items-center justify-center space-y-2 transition-opacity duration-500 ${isClicked ? 'opacity-100' : 'opacity-0'
                        }`}
                >
                    {isClicked && (
                        <>
                            <Link
                                href={'https://play.google.com/store/apps/details?id=com.genxproject'}
                                legacyBehavior
                                className='text-sm'
                            >
                                <div className='flex justify-center items-center gap-1'>
                                    <img src='/google-play.svg' />  Get App
                                </div>
                            </Link>
                        </>
                    )}
                </div>
                <div className='block md:hidden ml-1 absolute top-1/2 -translate-y-1/2 transform right-0 focus:outline-0'>
                    {
                        isClicked ? (
                            <span onClick={() => setIsClicked(!isClicked)} className='p-1 focus:outline-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>

                            </span>
                        ) : (
                            <span onClick={() => setIsClicked(!isClicked)} className='p-1 cursor-pointer shrink-0 focus:outline-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                </svg>
                            </span>
                        )
                    }


                </div>

                {/* Rotated Button */}
                <div
                    className={` focus:outline-0 hidden md:block absolute top-1/2 -translate-y-1/2 transform ${isClicked ? '-right-12' : '-right-12'
                        } -rotate-90 cursor-pointer transition-all duration-300`}
                    onClick={() => setIsClicked(!isClicked)}
                >
                    <span className="font-bold text-xs whitespace-nowrap">Download Mobile App</span>
                </div>
            </div >
        </div >
    );
};

export default FloatingAppQr;
