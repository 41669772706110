'use client'
import { useState, useEffect } from "react";

const useDeviceType = () => {
    const [deviceType, setDeviceType] = useState("laptop"); // Default to laptop/desktop

    useEffect(() => {
        const updateDeviceType = () => {
            const width = window.innerWidth;

            if (width < 768) {
                setDeviceType("mobile");
            } else if (width >= 768 && width <= 1024) {
                setDeviceType("tablet");
            } else {
                setDeviceType("laptop");
            }
        };

        // Initial check
        updateDeviceType();

        // Add a resize listener
        window.addEventListener("resize", updateDeviceType);

        // Cleanup listener on unmount
        return () => window.removeEventListener("resize", updateDeviceType);
    }, []);

    return deviceType;
};

export default useDeviceType;
