'use client';
import React, { useState, useCallback } from 'react';
import CouponsModal from './CouponsModal';
import { useCart } from '@/context/cart/CartContext';
const Coupons = ({ buyNow }) => {
  const { setCouponId, appliedCoupon, setAppliedCoupon } = useCart();
  const [isCouponClicked, setIsCouponClicked] = useState(false);
  const handleClose = useCallback((couponName) => {
    setIsCouponClicked((preValue) => !preValue);
    setAppliedCoupon(couponName);
  }, []);

  const handleClear = () => {
    setCouponId('');
    setAppliedCoupon('');
  };

  return (
    <div className='flex flex-col'>
      {
        !appliedCoupon ? (
          <div
            onClick={() => setIsCouponClicked(true)}
            className='flex h-fit cursor-pointer flex-col '
          >
            <div className='text-sm flex  flex-row gap-x-4 text-red-500  cursor-pointer'>
              Apply Coupon
            </div>
          </div>
        ) : null
      }



      {/* {appliedCoupon ? (
        <div className='w-full  flex justify-between items-center gap-x-2 rounded  px-6 py-1.5 border'>
          <div className='flex '>
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                class='h-6 w-6 text-[#535665]'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z'
                />
              </svg>
            </span>
            <div className='flex flex-col'>
              <span>Coupon Applied</span>
              <span className=' text-left flex text-[#0A2D6D] font-medium'>{appliedCoupon}
                <span className='ml-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-[#0A2D6D]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                  </svg>
                </span>
              </span>

            </div>
          </div>
          <button onClick={() => handleClear()} className=' text-red-500 py-2 px-4'>Remove</button>
        </div>
      ) : null} */}
      {isCouponClicked ? (
        <CouponsModal
          buyNow={buyNow}
          isVisible={isCouponClicked}
          onClose={handleClose}
        />
      ) : null}
    </div>
  );
};

export default Coupons;
