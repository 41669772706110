'use client';
import React from 'react';
import styles from './render.html.module.css'
const RenderHTML = ({ htmlContent }) => {
  return (
    <div>
      <div className={`${styles.customList} `} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default RenderHTML;
