'use client';
import React, { useState, useEffect } from 'react';
import BASE_URL from '../constants';
import getToken from '@/hook/getToken';

const ExpectedDelivery = React.memo(({ sellerId, productId }) => {
  const [deliveryData, setDeliveryData] = useState(undefined);
  const [error, setError] = useState(false);
  const [pincode, setPincode] = useState('');

  const fetchData = async (url, token) => {
    const headers = token
      ? {
        Authorization: `Bearer ${token}`,
      }
      : {};

    try {
      const response = await fetch(url, { headers });

      if (!response.ok) {
        if (response.status === 404) {
          setDeliveryData('Not available');
          setError(true);
        }
        console.error('Failed to fetch data from the API:', response.status);
        return;
      }

      const data = await response.json();
      if (!data || !data.estimated_delivery_days) {
        console.error('No estimated delivery days received from the API');
        return;
      }

      const deliveryDays = parseInt(data.estimated_delivery_days);
      if (isNaN(deliveryDays)) {
        console.error('Invalid estimated delivery days received from the API');
        return;
      }
      if (data?.pincode) {
        setPincode(data.pincode);
      }
      const currentDate = new Date();
      const deliveryDate = new Date(
        currentDate.setDate(currentDate.getDate() + deliveryDays)
      );
      setDeliveryData(deliveryDate.toDateString());
    } catch (error) {
      console.error('Error fetching data from the API:', error);
    }
  };

  useEffect(() => {
    const apiUrl = `${BASE_URL}/v1/orders/check-delivery-date?sellerId=${sellerId}&productId=${productId}`;
    const token = getToken();
    if (productId && sellerId && token) {
      fetchData(apiUrl, token);
    }
  }, [productId, sellerId]);

  const handleDelivery = (value) => {
    if (value.length > 6) return;
    setPincode(value);
    setError(false);
    setDeliveryData(undefined);
    if (value.length == 6) {
      const apiUrl = `${BASE_URL}/v1/orders/check-delivery-date?sellerId=${sellerId}&pincode=${value}&productId=${productId}`;
      fetchData(apiUrl);
    }
  };

  const showMsg = () => {
    if (pincode.length !== 6) {
      return 'Enter Pincode to check delivery date';
    }
    if (error) {
      return 'Delivery not available';
    }
    if (deliveryData) {
      return `Expected delivery by ${deliveryData}`;
    }
  };

  return (
    <div>
      <div className='flex flex-row items-center space-x-5'>
        <h3 className='text-sm font-bold text-[#1D364D]'>Delivery</h3>
        <form className='flex-1' onSubmit={(e) => e.preventDefault()}>
          <input
            type='number'
            name='pin'
            value={pincode}
            onChange={(e) => handleDelivery(e.target.value)}
            pattern='[0-9]{6}'
            maxLength='6'
            className='w-20 appearance-none rounded-lg border px-1.5 outline-none focus-within:ring-1'
            placeholder='Pin'
          />
        </form>
      </div>
      <div
        className={`text-sm ${error ? 'text-red-500' : 'text-[#1D364D]'} font-normal`}
      >
        {showMsg()}
      </div>
    </div>
  );
});

export default ExpectedDelivery;
