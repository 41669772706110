'use client'
import React, { useEffect, useState } from 'react'
import Heading from './Heading'
import SubHeading from './SubHeading'
import ShowImage from './ShowImage'
import useCustomSWR from '@/hook/useCustomSWR'
import { useInView } from "react-intersection-observer";
import Link from 'next/link'
const WFHTemplate1 = ({ data }) => {
    const { heading, sub_heading, image, mob_image, ids, redirect_url } = data;
    const categoriesId = JSON.stringify(ids?.map(d => d.id))
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '500px 0px',
        threshold: 0,
    });
    const { data: listData, isLoading } = useCustomSWR(`/v1/cms/get-filter-content?filter_type=category&ids=${categoriesId}&limit=20`, inView)

    if (!inView) return <div ref={ref}></div>;
    return (
        <div
            ref={ref}
            className='flex flex-col scroll-offset'
        >
            <div className='md:py-3 lg:py-5'>
                <Heading text={heading} />
                <SubHeading text={sub_heading} />
            </div>
            <ShowImage image={image} mobImage={mob_image} redirectUrl={redirect_url} />
            <div className='my-5 flex flex-row flex-wrap justify-center gap-2 md:gap-5 rounded-xl'>
                {
                    listData?.response?.data?.map(c => (
                        <Link
                            target='_blank'
                            href={c.child_categories_path}
                            key={c._id}
                            className={`relative shrink-0 basis-[30%] md:basis-[18%] cursor-pointer overflow-hidden rounded-xl`}
                        >
                            <div>
                                <img
                                    loading='lazy'
                                    src={c.image}
                                    alt={c.name}
                                    className='h-16 w-full rounded-md object-cover sm:h-24 md:h-28 lg:h-40'
                                />
                            </div>

                            <div className='absolute inset-x-0 bottom-0 hidden bg-black bg-opacity-10 backdrop-blur sm:block'>
                                <h3 className='text-center text-[10px] font-semibold text-white sm:text-xs lg:text-base lg:leading-10'>
                                    {c.name}
                                </h3>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}

export default WFHTemplate1