import React from 'react'
import Link from 'next/link';
import { useMediaQuery } from '@react-hook/media-query';
const ShowImage = ({ image, mobImage, alt, style, redirectUrl }) => {
    const isMobile = useMediaQuery("(max-width: 640px)")
    const imageUrl = isMobile ? mobImage : image
    if (!imageUrl) return null
    if (redirectUrl) {
        return (
            <Link
                href={redirectUrl}
                target='_blank'
                className={`block ${style}`}
            >
                <img className='w-full h-full object-cover' alt={alt} src={imageUrl} loading='lazy' />
            </Link>
        )
    }
    return (
        <div className={`${style}`}>
            <img className='w-full h-full object-cover' alt={alt} src={imageUrl} loading='lazy' />
        </div>
    )
}

export default ShowImage