'use client';
import React, { useState } from 'react';
import CartModal from './Modal';
const CartButton = ({ cart }) => {
  const [cartIsOpen, setCartIsOpen] = useState(false);
  let count = 0;
  let cart_products = [];

  if (cart) {
    count = cart?.totalCount || 0;
    cart_products = cart?.cart_products || [];
  }
  return (
    <>
      <CartModal
        isOpen={cartIsOpen}
        onClose={() => setCartIsOpen(false)}
        cart={cart}
      />
      <div className='relative'>
        <button onClick={() => setCartIsOpen(true)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='h-6 w-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z'
            />
          </svg>
        </button>
        {count ? (
          <div className='absolute right-0 top-0 -mr-3 -mt-3 flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-red-500 text-xs text-white'>
            {count}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CartButton;
