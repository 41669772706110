'use client';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from '@/context/AuthContext';
import BASE_URL from '../constants';
import getToken from '@/hook/getToken';

const Signup = () => {
  const { isSignUpOpen, setIsSignUpOpen } = useAuth();
  const [signupFormValues, setSignupFormValues] = useState({
    name: '',
    gender: 'male',
    avatar: '', // Set a default value
  });

  const fetchSignupData = async () => {
    try {
      const accessToken = getToken();
      if (!accessToken) return;
      const response = await fetch(`${BASE_URL}/v1/auth/b2c/me`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      // Update signupFormValues with fetched data
      setSignupFormValues({
        name: data.userDetails.name || '',
        gender: data.userDetails.gender || 'male', // Default to "male" if gender is not provided
        avatar: data.userDetails.avatar || '',
      });
    } catch (error) {
      console.error('Error fetching signup data:', error);
    }
  };

  useEffect(() => {
    fetchSignupData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupFormValues((preValue) => {
      return { ...preValue, [name]: value };
    });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    let accessToken = getToken();
    try {
      const signupResponse = await fetch(
        `${BASE_URL}/v1/auth/b2c/complete-signup`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(signupFormValues),
        }
      );

      if (signupResponse.ok) {
        toast.success('Profile Updated Successfully');
        setIsSignUpOpen(false);
        // Handle successful signup if needed
      } else {
        const signupErrorData = await signupResponse.json();
        toast.error(signupErrorData.message);
        // Handle signup errors if needed
      }
    } catch (error) {
      console.error('Signup failed:', error);
    }
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        setSignupFormValues((preValue) => {
          return { ...preValue, avatar: base64Image };
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = (e) => {
    if (e.target.id === 'container') {
      setIsSignUpOpen(false);
    }
  };
  if (!isSignUpOpen) return null;
  return (
    <div
      id='container'
      onClick={(e) => {
        handleClick(e);
      }}
      className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 text-xs backdrop-blur-sm'
    >
      <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
        <div className='w-96 rounded-lg bg-white p-8'>
          <div className='flex items-start justify-between'>
            <h2 className='mb-4 text-2xl font-semibold'>Update Profile</h2>
            <button
              onClick={() => {
                setIsSignUpOpen(false);
              }}
              className='rounded text-lg'
            >
              X
            </button>
          </div>
          <form
            id='signupForm'
            onSubmit={(e) => handleSignup(e)}
            className='mx-auto w-full max-w-md'
          >
            <div className='mb-4'>
              <label htmlFor='name' className='block pb-1 text-gray-600'>
                Name:
              </label>
              <input
                name='name'
                type='text'
                id='name'
                className='w-full border p-2 focus:border-blue-500 focus:outline-none'
                value={signupFormValues.name}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='gender' className='block pb-1 text-gray-600'>
                Gender:
              </label>
              <select
                name='gender'
                id='gender'
                className='w-full border p-2 focus:border-blue-500 focus:outline-none'
                value={signupFormValues.gender}
                onChange={(e) => handleChange(e)}
              >
                <option value='male'>Male</option>
                <option value='female'>Female</option>
                <option value='other'>Other</option>
              </select>
            </div>
            <div className='mb-4'>
              <label
                htmlFor='signupAvatar'
                className='block pb-1 text-gray-600'
              >
                Image:
              </label>
              <input
                type='file'
                id='avatar'
                name='avatar'
                accept='image/*'
                onChange={handleAvatarChange}
              />
            </div>
            <div className='flex justify-center'>
              <button
                className='rounded bg-blue-500 px-28 py-2 text-white hover:bg-blue-600'
                type='submit'
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
