'use client'
import React from 'react';
import componentRegistry from './ComponentRegistry';
import useCustomSWR from '@/hook/useCustomSWR';
const HomeCmsPreview = () => {
    const { data, isLoading } = useCustomSWR('/v1/cms/get-active-content', true)
    return (
        <>
            <div className='flex flex-row relative'>
                <div className={`w-full text-left   flex flex-col gap-y-5 md:gap-y-7 lg:gap-y-10 p-2 `}>
                    {data?.response?.data?.map((item, index) => {
                        const ComponentToRender = componentRegistry[item.component];
                        const sectionId = item.title?.replace(/ /g, '_');
                        if (ComponentToRender) {
                            return (
                                <div key={index} id={sectionId}>
                                    <ComponentToRender data={item.data} />
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        </>
    );
};

export default HomeCmsPreview;
