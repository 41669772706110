import React, { useEffect, useState } from 'react';
import { useCart } from '@/context/cart/CartContext';
import CouponItem from './CouponItem';
import BASE_URL from '../constants';
import getToken from '@/hook/getToken';
const CouponsModal = ({ isVisible, onClose, buyNow }) => {
  const { setCouponId, couponId } = useCart();
  const [couponList, setCouponList] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState('');
  const [error, setError] = useState('');
  const getCoupons = async (url, token) => {
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Assuming your token is a bearer token
        },
      });
      const data = response.json();
      return data;
      // Handle the response here
    } catch (error) {
      // Handle errors here
      console.log('Error :', error);
    }
  };

  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId');
    const apiUrl = buyNow
      ? sessionId
        ? `${BASE_URL}/v1/coupon/getCouponAsPerCart/b2c?buy_now=true&session_id=${sessionId}`
        : `${BASE_URL}/v1/coupon/getCouponAsPerCart/b2c?buy_now=true`
      : `${BASE_URL}/v1/coupon/getCouponAsPerCart/b2c`;

    const token = getToken();
    getCoupons(apiUrl, token)
      .then((data) => {
        setCouponList(data.coupons);
      })
      .catch((err) => console.log('fetch-coupons', err));
  }, []);

  const handleApply = (id, couponName) => {
    sessionStorage.setItem('appliedCouponId', id)
    sessionStorage.setItem('couponName', couponName)
    setAppliedCoupon(couponName);
    setCouponId(id);
    onClose(couponName);
  };

  const handleClose = (e) => {
    const { id } = e.target;
    if (id === 'coupon-modal') {
      onClose();
    }
  };
  if (!isVisible) {
    return null;
  }

  const handleApplyCoupon = () => {
    const coupon = couponList.find(
      (item) => item.coupon_code === appliedCoupon
    );
    if (coupon) {
      setCouponId(coupon._id);
      onClose(appliedCoupon);
    } else {
      setError('Invalid Coupon');
    }
  };

  return (
    <div
      onClick={(e) => handleClose(e)}
      id='coupon-modal'
      className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-25 text-xs backdrop-blur-sm'
    >
      <div className='mx-4 rounded-md bg-white p-4 max-w-sm'>
        <div className='flex flex-col'>
          <div className='sticky top-0 z-50 backdrop:blur-sm'>
            <div className='flex items-center justify-between'>
              <p className='text-[#3D4152] font-bold text-lg'>
                Available Coupons
              </p>
              <p
                onClick={() => onClose()}
                className='cursor-pointer p-2 text-2xl text-[#3D4152]'
              >
                X
              </p>
            </div>
            {/* //input section for coupon code  */}
            <div className='border[#A9ABB2] my-5 flex flex-row border border-solid'>
              <input
                className='w-full px-4 py-3 outline-none placeholder:text-[#A9ABB2] md:w-60'
                type='search'
                placeholder='Enter coupon code'
                onChange={(e) => setAppliedCoupon(e.target.value)}
                value={appliedCoupon}
              />
              <button
                onClick={() => handleApplyCoupon()}
                className='flex w-40 items-center justify-center bg-black px-4 py-3 text-sm font-bold text-white'
              >
                Apply
              </button>
            </div>
            {error && (
              <p className='pb-2 text-center text-xs text-red-500'>{error}</p>
            )}
          </div>
          {/* // Available Coupons */}
          {couponList?.length ? (
            <div className='border[#A9ABB2] no-scrollbar flex max-h-[20rem] flex-col space-y-4 overflow-scroll border border-solid py-5 '>
              {couponList?.map((item, index) => (
                <CouponItem
                  key={item._id}
                  couponName={item.coupon_code}
                  desc={item.description}
                  id={item._id}
                  handleApply={handleApply}
                />
              ))}
            </div>
          ) : (
            <h1 className='text-center text-gray-600'>No Coupons Available</h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default CouponsModal;
