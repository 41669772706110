import React, { useRef, useState } from 'react';

const ProductGallery = ({ productImages }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
    containerRef.current.style.scrollBehavior = 'auto';
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    containerRef.current.style.scrollBehavior = 'smooth';
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const deltaX = e.clientX - startX;
    containerRef.current.scrollLeft = scrollLeft - deltaX;
  };

  return (
    <div className='block w-full shrink-0 overflow-x-hidden md:sticky md:top-0 md:w-[60%]'>
      <div
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        ref={containerRef}
        className='whitespace-no-wrap no-scrollbar relative flex h-full min-h-fit w-full flex-row gap-5 overflow-x-auto rounded-xl'
      >
        {productImages &&
          Object.keys(productImages).map((key) => {
            if (key === '_id') {
              // Skip the _id property
              return null;
            }
            return (
              <div
                ref={contentRef}
                className='w-96 flex-shrink-0 cursor-pointer items-center overflow-hidden'
                key={key}
              >
                <img
                  loading='lazy'
                  src={productImages[key]}
                  alt={`Image ${key}`}
                  className='h-full w-full rounded-xl object-cover'
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProductGallery;
