import { useState } from 'react';
import React from 'react';
import BASE_URL from '../constants';

export const Logout = () => {
  const handleLogout = async () => {
    const access_token = localStorage.getItem('access_token');
    try {
      const response = await fetch(`${BASE_URL}/v1/auth/b2c/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({}),
      });

      // Handle the response from the API
      if (response.ok) {
        localStorage.removeItem('access_token');
        // Remove refresh_token from localStorage
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_name');
        localStorage.removeItem('phone');
        window.location.href = '/';
        setIsLogin(false);
      } else {
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_name');
        localStorage.removeItem('phone');
        localStorage.removeItem('registration_complete');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  return (
    <div>
      <div className='flex flex-col'>
        <p className='text-sm text-[#4285f4] font-medium' onClick={handleLogout}>
          Logout
        </p>
      </div>
    </div>
  );
};
