'use client';
import Link from 'next/link';
import React from 'react';
import Cart from '@/components/cart';
import Wishlist from '@/components/wishlist';
import { useAuth } from '@/context/AuthContext';
import getToken from '@/hook/getToken';
import { useRouter } from 'next/navigation';

const MobileFooter = () => {
  const { isSignInOpen, setIsSignInOpen } = useAuth();
  const access_token = getToken();
  const router = useRouter();

  const handleClick = (event) => {
    event.preventDefault();
    if (!access_token) {
      setIsSignInOpen(true);
    } else {
      router.push('/user');
    }
  };

  return (
    <footer className='sticky inset-x-0 bottom-0 z-20 w-full bg-gray-800 p-3 text-white md:hidden'>
      <div className='flex items-center justify-between'>
        <button className=' '>
          <Link href='/'>
            <img
              className='block h-8 w-8 md:hidden'
              src='https://res.cloudinary.com/genx21/image/upload/v1733467899/images/ymrlt4hltxftbonhpsd3.png'
              alt='21genx-logo'
            />
          </Link>
        </button>

        <button className='' onClick={handleClick}>
          <Link href='/'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='h-6 w-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z'
              />
            </svg>
          </Link>
        </button>

        <button className=''>
          <Cart />
        </button>

        <button className=''>
          <Link href='/user/wishlist'>
            <Wishlist />
          </Link>
        </button>
      </div>
    </footer>
  );
};

export default MobileFooter;
