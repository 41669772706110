import React from 'react'
import ShowImage from './ShowImage'
const HomepageBanner = ({ data }) => {
    const { image, mob_image, redirect_url } = data;
    return (
        <div>
            <ShowImage
                image={image}
                mobImage={mob_image}
                redirectUrl={redirect_url}
                style='w-full h-full'
            />
        </div>
    )
}

export default HomepageBanner