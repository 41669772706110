'use client'
import React, { useState } from 'react'

const ReadMoreWrapper = ({ children }) => {
    const [isClicked, setIsClicked] = useState(false)

    return (
        <div className='max-w-7xl mx-auto'>
            <div
                className={`relative w-full overflow-hidden mt-10 transition-all duration-500 ease-in-out ${isClicked ? 'h-auto' : 'h-40'}`}
                style={{ maxHeight: isClicked ? 'none' : '160px' }} // Ensure smooth transition for height change
            >
                {children}
                {!isClicked && (
                    <div
                        className="absolute bottom-0 left-0 right-0 h-10 bg-gradient-to-t from-white via-white/80 to-transparent pointer-events-none"
                    ></div>
                )}
            </div>
            <div className="flex justify-center items-center mb-5">
                <button onClick={() => setIsClicked(prev => !prev)} className="">
                    {isClicked ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
                        </svg>
                    )}
                </button>
            </div>
        </div>
    )
}

export default ReadMoreWrapper;
