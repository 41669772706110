'use client';
import React, { useState, useEffect } from 'react';
import Card from '@/components/product/Card';
import useCustomSWR from '@/hook/useCustomSWR';
import { Baskervville, Open_Sans } from 'next/font/google';
import { useInView } from 'react-intersection-observer';
import { Skeleton } from '@mui/material';
const baskervville = Baskervville({
  weight: ['400'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
});

const open_sans = Open_Sans({
  weight: ['400', '700', '800'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
});

const ProductSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px 0px',
    threshold: 0,
  });

  useEffect(() => {
    if (!inView) return;
    setIsVisible(true);
  }, [inView]);

  const { data, isLoading, isError, revalidate } = useCustomSWR(
    `/v1/products/get-all-products?page=1&limit=20&random=true&stock_product=true`,
    inView
  );
  const products = data?.productList;
  if (!isVisible) return <div ref={ref}></div>;
  return (
    <div ref={ref} className='flex flex-col p-2 md:p-4'>
      <div className='mb-10'>
        <p className='py-1 text-center text-xl sm:text-2xl md:text-3xl font-bold uppercase'>
          DISCOVER LUXURY REDEFINED
        </p>
        <p className='text-center text-xs md:text-sm font-normal text-opacity-50'>
          {' '}
          Explore show-stopping products that elevate your lifestyle.
        </p>
      </div>
      <div className='mt-2 grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-5 xl:grid-cols-4'>
        {isLoading &&
          Array.from({ length: 12 }, (_, index) => (
            <div className='h-52 w-full overflow-hidden rounded-lg md:h-96 2xl:h-[30rem]'>
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='100%'
                height='100%'
              />
            </div>
          ))}
        {products?.length !== 0 &&
          products?.map((product) => (
            <Card key={product._id} product={product} />
          ))}
      </div>
      <div className='flex flex-col py-5 md:py-7 lg:py-10'>
        <h3
          className={`${baskervville.className} text-center text-4xl md:text-5xl lg:text-7xl font-normal leading-snug text-black`}
        >
          <span className={`font-sans font-extrabold ${open_sans.className}`}>
            OK,
          </span>{' '}
          <br />
          show me{' '}
          <span className={`font-sans font-bold ${open_sans.className}`}>
            all
          </span>{' '}
          <br />
          products!
        </h3>
      </div>
      <a
        href={'/product'}
        className='flex items-center justify-center text-lg uppercase underline mb-5'
      >
        Discover More {` -->`}
      </a>
    </div>
  );
};

export default ProductSection;
