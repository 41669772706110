'use client'
import React, { useState, useEffect } from 'react'
import Heading from './Heading'
import SubHeading from './SubHeading'
import ProductCard from '../../ProductCard'
import useCustomSWR from '@/hook/useCustomSWR';
import { Skeleton } from '@mui/material';
import { useInView } from "react-intersection-observer";
import RedirectLink from '../../RedirectLink'
const AwardTemplate1 = ({ data }) => {
    const { heading, sub_heading, ids } = data;
    const [selectedId, setSelectedId] = useState(ids[0]?.id)
    const awardIds = JSON.stringify(ids?.map(d => d.id))

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '500px 0px',
        threshold: 0,
    });
    const { data: listData, isLoading } = useCustomSWR(`/v1/cms/get-filter-content?filter_type=award&ids=${awardIds}&limit=20`, inView)
    const { data: productData, isLoading: productLoading } = useCustomSWR(`/v1/products/get-all-products?filter[awards][$eq]=${selectedId}&random=yes&page=1&limit=5&stock_product=true`, inView)

    const handleChange = (id) => {
        setSelectedId(id)
    }
    if (!inView) return <div id='award' ref={ref}></div>;
    return (
        <div
            id='award'
            ref={ref}
            className='flex flex-col '
        >
            <div className='md:py-3 lg:py-5'>
                <Heading text={heading} />
                <SubHeading text={sub_heading} />
            </div>
            <div className='home-heading w-full sm:justify-center'>
                {
                    listData?.response?.data?.map(c => (
                        <AwardHeadingCard
                            data={c}
                            id={selectedId}
                            handleChange={handleChange}
                        />
                    ))
                }
            </div>
            <div className='home-product-section'>
                {
                    productLoading && Array.from({ length: 6 }, (_, index) => (
                        <div className='h-52 w-full overflow-hidden rounded-lg md:h-96 2xl:h-[30rem]'>
                            <Skeleton
                                animation='wave'
                                variant='rectangular'
                                width='100%'
                                height='100%'
                            />
                        </div>
                    ))
                }
                {productData?.productList &&
                    productData?.productList.length !== 0 &&
                    productData?.productList?.map((product, index) => {
                        return (
                            <ProductCard
                                key={product._id}
                                product={product}
                                style={'w-full h-52 md:h-96 2xl:h-[30rem]'}
                                iconStyle={`w-7 h-7`}
                                textSize={`text-sm`}
                                quickViewWidth={24}
                                quickViewHeight={24}
                            />
                        );
                    })}

            </div>
            {productData?.productList.length && (
                <RedirectLink
                    name='award'
                    id={selectedId}
                    section={'Award'}
                    listData={listData?.response?.data}
                />
            )}
        </div>
    )
}

export default AwardTemplate1

const AwardHeadingCard = ({ handleChange, id, data }) => {
    const { _id, name, image } = data;
    return (
        <div
            className={`relative h-16 w-16 shrink-0 transform cursor-pointer overflow-hidden rounded-full transition-transform duration-300 ease-in-out hover:scale-110 hover:opacity-90 hover:shadow-lg xl:h-20 xl:w-20 ${id == _id ? 'ring-2 ring-gray-400 ' : 'grayscale filter border '} overflow-hidden`}
            onMouseOver={() => handleChange(_id)}
        >
            <div className='h-full w-full'>
                <img
                    loading='lazy'
                    width={100}
                    height={100}
                    src={image}
                    alt={name}
                    className='h-full w-full object-cover'
                />
            </div>
        </div>
    )
}