import React from 'react';

const CouponItem = ({ couponName, desc, id, handleApply }) => {
  return (
    <div className='flex flex-col px-4 '>
      <div className='flex w-fit border border-[#DACEB7] bg-[#FFFAE6] px-4 py-2'>
        <img
          className='h-5 w-5 object-cover'
          src='/assets/image/coupon-logo.png'
          alt='logo'
        />
        <p className='ml-2 px-4 text-sm'> {couponName}</p>
      </div>
      <div className='py-2'>
        <p className=' text-xs text-[#686B78] whitespace-pre-wrap'>{desc}</p>
      </div>
      <button
        onClick={() => handleApply(id, couponName)}
        className='w-full border border-[#6691E7] px-4 py-2 font-bold text-[#6691E7] hover:scale-105'
      >
        Apply Coupon
      </button>
    </div>
  );
};

export default CouponItem;
