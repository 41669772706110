'use client'
import React, { useState, useEffect } from 'react'
import Heading from './Heading'
import SubHeading from './SubHeading'
import ProductCard from '../../ProductCard';
import useCustomSWR from '@/hook/useCustomSWR';
import { Skeleton } from '@mui/material';
import { useInView } from "react-intersection-observer";
import { Virtual, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Link from 'next/link';

const UtilityTemplate1 = ({ data }) => {
    const { heading, sub_heading, ids } = data;
    const [selectedId, setSelectedId] = useState(null)
    const [secondRowId, setSetSecondRowId] = useState(null)
    const utilityIds = JSON.stringify(ids?.map(d => d.id))

    const { ref, inView } = useInView({

        triggerOnce: true,
        rootMargin: '500px 0px',
        threshold: 0,
    });

    const { data: listData, isLoading } = useCustomSWR(`/v1/cms/get-filter-content?filter_type=utility&ids=${utilityIds}`, inView)
    const { data: firstRowProduct, isLoading: firstRowLoading } = useCustomSWR(`/v1/products/get-all-products?filter[utility_tags][$eq]=${selectedId}&random=yes&page=1&limit=12&stock_product=true`, inView)
    const { data: secondRowProduct, isLoading: secondRowLoading } = useCustomSWR(`/v1/products/get-all-products?filter[utility_tags][$eq]=${secondRowId}&random=yes&page=1&limit=12&stock_product=true`, inView)
    const handleChange = (id, type) => {
        if (type == 'first') {
            return setSelectedId(id)
        }
        if (type == 'second') {
            setSetSecondRowId(id)
        }
    }

    if (!inView) return <div id='utility' ref={ref}></div>;
    const firstRow = listData?.response?.data?.slice(0, 4)
    const secondRow = listData?.response?.data?.slice(4, 8)
    return (
        <div
            id='utility'
            ref={ref}
            className='flex flex-col scroll-offset'
        >
            <div className='md:py-3 lg:py-5'>
                <Heading text={heading} />
                <SubHeading text={sub_heading} />
            </div>
            {/* first Row */}
            <div className='no-scrollbar grid w-full grid-cols-2 items-center justify-center gap-2 overflow-x-scroll py-2 md:grid-cols-4 md:gap-5'>

                {
                    firstRow?.map(c => (
                        <UtilityHeadingCard
                            data={c}
                            id={selectedId}
                            handleChange={(name) => handleChange(name, 'first')}
                        />
                    ))
                }

            </div>
            <div className='w-full'>
                <Swiper
                    modules={[Virtual]}
                    slidesPerView={6}
                    // centeredSlides={true}
                    spaceBetween={14}
                    // navigation={true}
                    virtual
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 2,
                        },
                        480: {
                            slidesPerView: 2,
                        },
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 3,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                        },
                    }}
                >
                    {firstRowLoading &&
                        Array.from({ length: 6 }, (_, index) => (
                            <SwiperSlide key={index} virtualIndex={index}>
                                <div className='h-80 w-full overflow-hidden rounded-lg'>
                                    <Skeleton
                                        animation='wave'
                                        variant='rectangular'
                                        width='100%'
                                        height='100%'
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    {firstRowProduct?.productList &&
                        firstRowProduct?.productList?.length !== 0 &&
                        firstRowProduct?.productList?.map((product, index) => {
                            return (
                                <SwiperSlide key={product._id + index} virtualIndex={index}>
                                    <ProductCard
                                        key={product._id}
                                        isSwiper={true}
                                        product={product}
                                        style={'w-full h-52 md:h-80'}
                                        iconStyle={`w-7 h-7`}
                                        textSize={`text-sm`}
                                    />
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </div>
            {/* // Second Row */}
            <div className='no-scrollbar grid w-full grid-cols-2 items-center justify-center gap-2 overflow-x-scroll py-2 md:grid-cols-4 md:gap-5 mt-5'>

                {
                    secondRow?.map(c => (
                        <UtilityHeadingCard
                            data={c}
                            id={secondRowId}
                            handleChange={(name) => handleChange(name, 'second')}
                        />
                    ))
                }

            </div>
            <div className='w-full'>
                <Swiper
                    modules={[Virtual]}
                    slidesPerView={6}
                    // centeredSlides={true}
                    spaceBetween={14}
                    // navigation={true}
                    virtual
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 2,
                        },
                        480: {
                            slidesPerView: 2,
                        },
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 3,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                        },
                    }}
                >
                    {secondRowLoading &&
                        Array.from({ length: 6 }, (_, index) => (
                            <SwiperSlide key={index} virtualIndex={index}>
                                <div className='h-80 w-full overflow-hidden rounded-lg'>
                                    <Skeleton
                                        animation='wave'
                                        variant='rectangular'
                                        width='100%'
                                        height='100%'
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    {secondRowProduct?.productList &&
                        secondRowProduct?.productList?.length !== 0 &&
                        secondRowProduct?.productList?.map((product, index) => {
                            return (
                                <SwiperSlide key={product._id + index} virtualIndex={index}>
                                    <ProductCard
                                        key={product._id}
                                        isSwiper={true}
                                        product={product}
                                        style={'w-full h-52 md:h-80'}
                                        iconStyle={`w-7 h-7`}
                                        textSize={`text-sm`}
                                    />
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </div>
            <div className='mt-5 flex flex-col items-center justify-center py-2'>
                <Link
                    href={`/product?name=utility`}
                    className='flex w-fit items-center gap-2 rounded px-4 text-center text-lg font-normal text-gray-700'
                >
                    {`Explore all utility -->`}
                </Link>
            </div>
        </div >
    )
}

export default UtilityTemplate1

const UtilityHeadingCard = ({ handleChange, id, data }) => {
    const { _id, name, image } = data;
    return (
        <div
            onMouseEnter={() => handleChange(name)}
            onClick={() => handleChange(name)}
            className='flex flex-col'
        >
            <div
                className={`w-full  shrink-0 cursor-pointer overflow-hidden rounded-lg shadow  ${id === name ? 'border-2 border-solid border-blue-500' : 'grayscale filter'}`}
            >
                <img
                    src={image}
                    alt={name}
                    width={400}
                    height={400}
                    loading=''
                    className='h-full w-full object-cover'
                />
            </div>
            <h2 className='py-2 text-center text-sm font-bold md:text-base lg:text-xl'>
                <span className='text-[#4e77da]'>{name}</span>
            </h2>
        </div>
    )
}